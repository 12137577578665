<template>
  <div>
    <v-layout wrap justify-start>
      <v-flex xs12 sm12 md6 lg6 text-left px-4>
        <span class="itemHeading">Career Graph</span>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 text-end px-4>
        <v-btn
          style="font-family: kumbhBold"
          dark
          color="#005f32"
          @click="employeeDownload()"
        >
          Download Excel
        </v-btn>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 text-end px-4 pt-3 pt-lg-0 pt-md-0 pt-sm-0 >
        <v-btn
          style="font-family: kumbhBold"
          dark
          color="#005f32"
          @click="employeeDownloadPDF()"
        >
          Download PDF
        </v-btn>
      </v-flex>

    </v-layout>

    <v-layout wrap justify-center pt-4>
      <v-flex px-4>
        <v-card outlined elevation="1" class="pa-6">
          
          <v-layout wrap justify-center>
            <v-flex xs11 sm11 md11 lg12 xl12 text-left pa-3 >
              <v-layout wrap justify-start>
                <v-flex xs12 sm6 md4 lg3 xl4 v-if="empDetails && empDetails.employeeId">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                 Employee ID
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.employeeId }}
                  </span>
                </v-flex>

                <v-flex xs12 sm6 md4 lg3 xl4 v-if="empDetails && empDetails.name">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                  Name
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.name }}
                  </span>
                </v-flex>

                
    
                <v-flex xs12 sm6 md4 lg3 xl4 pt-lg-0 pt-md-0 pt-sm-0 pt-2 v-if="empDetails && empDetails.positionid">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Designation
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.positionid[0].name
                  }}</span>
                </v-flex>
    
                <v-flex xs12 sm6 md4 lg3 xl4 pt-lg-3 pt-md-3 pt-sm-3 pt-2 v-if="empDetails && empDetails.grade">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                Grade
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.grade }}</span>
                </v-flex>
    
                <v-flex xs12 sm6 md4 lg3 xl4 pt-lg-0 pt-md-0 pt-sm-3 pt-2 v-if="empDetails && empDetails.dateofjoining">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Date of Joining
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ formatDate(empDetails.dateofjoining) }}</span>
                </v-flex>
               
                <v-flex xs12 sm6 md4 lg3 xl4 pt-lg-3 pt-md-3 pt-sm-3 pt-2 v-if="empDetails && empDetails.promotion">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Promotion
                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.promotion }}</span>
                </v-flex>
                

                <v-flex xs12 sm6 md4 lg3 xl3 pt-lg-3 pt-md-3 pt-sm-3 pt-2  v-if="empDocDetails && empDocDetails.totalExperienceinYears">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Total experience in years

                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDocDetails.totalExperienceinYears }}</span>
                </v-flex>

                <!-- <v-flex xs12 sm6 md4 lg3 xl3 pt-lg-3 pt-md-3 pt-sm-3 pt-2  v-if="empDocDetails && empDocDetails.totalExperienceinYears">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Disciplinary Action Count

                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDocDetails.totalExperienceinYears }}</span>
                </v-flex> -->

                <v-flex 
                xs12 sm6 md4 lg3 xl3 pt-lg-3 pt-md-3 pt-sm-3 pt-2  
                v-if="disciplinaryActionCount"
                @click="redirectToDisciplinaryActions"
                style="cursor: pointer;"
              >
                <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                  Disciplinary Action Count
                </span>
                <br>
                <span style="font-family: kumbhRegular; font-size: 16px;">{{ disciplinaryActionCount }}</span>
              </v-flex>
              

                <v-flex xs12 pt-3 v-if="empDetails && empDetails.education">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Education

                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDetails.education }}</span>
                </v-flex>

                <v-flex xs12 pt-3 v-if="empDocDetails && empDocDetails.additionalQualification">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    Additional Qualification

                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDocDetails.additionalQualification }}</span>
                </v-flex>

                <v-flex xs12 pt-3 v-if="empDocDetails && empDocDetails.externalTraingOrWorkshop">
                  <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                    External Traing Or Workshop

                  </span>
                  <br>
                  <span style="font-family: kumbhRegular; font-size: 16px;">{{ empDocDetails.externalTraingOrWorkshop }}</span>
                </v-flex>

                

              </v-layout>
            </v-flex>
          </v-layout>
    
          <!-- Pie chart section aligned left -->
          
    
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-5 px-4>
      <v-flex xs4 pt-1>
        <v-select
        v-model="selectedSeries"
        :items="['Grade', 'PMR',]"
        label="Select Series" outlined dense
        clearable
      ></v-select>
      </v-flex>
      <v-flex xs12 sm6 md4 lg4 xl4 v-if="annualYear">
        <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
          Annual Year

        </span>
        <br>
        <span style="font-family: kumbhRegular; font-size: 16px;">{{ annualYear }}</span>
      </v-flex>
      <v-flex xs12>
        <apexchart ref="careerChart" type="line" height="400px" :options="chartOptions" :series="filteredSeries"></apexchart>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2 pb-4>
      <v-flex xs11>
        <v-data-table hide-default-footer :headers="headers" :items="empdata" class="elevation-1 pa-3 " dense>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      empdata:[],
      annualYear: "",
      disciplinaryActionCount:"",
      empDetails: {},
      empDocDetails:{},
     headers: [
        { text: "Year Code", value: "yearCode" },
        { text: "Year", value: "fyear" },
        { text: "Grade", value: "gradeValue" },
        { text: "PMR", value: "rating" },
      ],
      selectedSeries: "", // Initially empty
    careerGraph: [],
    series: [],
    chartOptions: {
      chart: {
        type: "line",
        toolbar: { show: true },
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#B22222", "#6AA84F", "#FFA500"],
      markers: {
        size: 4,
        hover: { size: 6 },
      },
      xaxis: {
        categories: [],
        title: { text: "Year" },
      },
      yaxis: {
        min: 0,
        title: { text: "Score / Salary" },
        tickAmount: 10,
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
    },
    };
  },
  created() {
    this.fetchEmployeeData();
    this.getTableData();
  },
  computed: {
  filteredSeries() {
    if (!this.selectedSeries) {
      // No selection: Show both Grade & PMR
      return this.series.filter((s) => s.name === "Grade" || s.name === "PMR");
    }
    // Show only selected series
    return this.series.filter((s) => s.name === this.selectedSeries);
  },
},
  methods: {
    redirectToDisciplinaryActions() {
    const employeeId = this.$route.query.employeeId; // Get employeeId from route query
    if (employeeId) {
      this.$router.push({ path: "/ViewDA" });
    } else {
      console.error("Employee ID not found");
    }
  },
    employeeDownload() {
      axios({
        method: "GET",
        url: "/employee/career/graph/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          employeeId: this.$route.query.employeeId
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Career Graph.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    employeeDownloadPDF() {
      axios({
        method: "post",
        url: "/employee/career/graph/pdf",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          employeeId: this.$route.query.employeeId
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType = response.headers["content-type"] || "application/pdf";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Career Graph.pdf"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    
    fetchEmployeeData() {
    axios({
      url: "/employee/career/graph",
      method: "post",
      data: { employeeId: this.$route.query.employeeId },
      headers: { "x-auth-token": localStorage.getItem("token") },
    })
      .then((response) => {
        this.careerGraph = response.data.careerGraph;
        const yearCodes = this.careerGraph.map((item) => item.yearCode);
        const grades = this.careerGraph.map((item) => item.gradeValue);
        const ratings = this.careerGraph.map((item) => item.rating);
        const salaries = this.careerGraph.map((item) => item.salary);

        this.chartOptions.xaxis.categories = yearCodes;
        this.series = [
          { name: "Grade", data: grades },
          { name: "PMR", data: ratings },
          { name: "Salary", data: salaries },
        ];
        this.empDetails = response.data.empDetails;
          this.empDocDetails=response.data.empDocDetails
          this.annualYear = response.data.annualYear;
          this.disciplinaryActionCount=response.data.disciplinaryActionCount

      })
      .catch((err) => console.error(err));
  },
    formatDate() {
      const dt = new Date();
      const day = String(dt.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
      const month = String(dt.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad
      const year = dt.getFullYear(); // Get full year
      return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
    },
    getTableData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/employee/career/graph/data/list",
        headers: { "x-auth-token": localStorage.getItem("token") },
       data:{
        employeeId: this.$route.query.employeeId
       }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.empdata = response.data.careerGraph;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // location.reload();
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-data-table .v-data-table-header tr th {
  text-align: left !important;
  /* Ensure text alignment */
  color: black !important;
  font-family: kumbhMedium;
  font-size: 13px !important;
  text-transform: uppercase;
  padding: 14px !important;

}
</style>